import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';


const ScopeGuard = (scopes) =>
  function GuardComponent({ children }) {
    const { isEnable } = useAuth();
    if (!isEnable(scopes)) {
      return <Redirect to="/403" />;
    }

    return (
      <>
        {children}
      </>
    );
  }

// ScopeGuard.propTypes = {
//   children: PropTypes.node
// };

export default ScopeGuard;
