export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  CODEFIN: 'CODEFIN',
};

export const CUSTOMER_SEARCH_TYPE = {
  PROGRESS_STATUS_ALL: "ALL",
	PROGRESS_STATUS_REGISTERED: "REGISTERED",
	PROGRESS_STATUS_VERIFIED: "VERIFIED",
	SEARCH_TYPE_ALL: "ALL",
	SEARCH_TYPE_FIRST_NAME_EN: "FIRST_NAME_EN",
	SEARCH_TYPE_LAST_NAME_EN: "LAST_NAME_EN",
	SEARCH_TYPE_FIRST_NAME_TH: "FIRST_NAME_TH",
	SEARCH_TYPE_LAST_NAME_TH: "LAST_NAME_TH",
};

export const KYC_TYPE = {
  SEVEN_ELEVEN: "7-11",
};