import { put, call, take } from 'redux-saga/effects'
import { NOTIFICATION_GET_LIST, updateNotificationList } from 'src/actions/NotificationAction'
import { apiGetNotifications } from 'src/api/NotificationApi'
import { handleStatusError } from '.'

export function* workerGetNotificationList() {
    try {
        const response = yield call(apiGetNotifications)
        if (response.data) {
            yield put(updateNotificationList(response.data.notifications))
        }
    } catch (error) {
        yield call(handleStatusError, error)
    }
}

export function* watcherGetNotificationList() {
    while (true) {
        yield take(NOTIFICATION_GET_LIST)
        yield call(workerGetNotificationList)
    }
}