import { all, fork, call } from "redux-saga/effects";
import { ERROR_CONNECTION_TIMEOUT, ERROR_SESSION_LOST } from "src/api/apiCall";
import { showAlert } from "src/components";
import { 
  watcherGetCustomers,
  watcherGetListCustomer,
  watcherGetCustomerInfo,
  watcherGetListUserCertificate,
  watcherSetBlockUser,
} from "./CustomerSaga";
import { watcherGetNotificationList } from "./NotificationSaga";

export function* handleStatusError(error) {
  // console.log("Error :: ", error);
  if (error?.status == 422) {
    error = new Error("GraphQL Error (422)");
  }

  if (error?.message == ERROR_SESSION_LOST) {
    // yield call(showAlert, getWord('operation_false'), 'Session Lost กรุณา Login ใหม่')
    // yield call(workerAutoRouting)
  } else if (error?.message == ERROR_CONNECTION_TIMEOUT) {
    // yield call(showAlert, getWord('error_topic'), getWord('connect_timeout'))
  } else {
    yield call(showAlert, "Something went wrong", error?.message);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetCustomers),
    fork(watcherGetNotificationList),
    fork(watcherGetListCustomer),
    fork(watcherGetCustomerInfo),
    fork(watcherGetListUserCertificate),
    fork(watcherSetBlockUser),
  ]);
}
