import { combineReducers } from 'redux';
import { CustomerReducer , CUSTOMER_STATE } from './CustomerReducer';
import { NotificaitonReducer , NOTIFICATION_STATE } from './NotificaitonReducer';

const rootReducer = combineReducers({
  [CUSTOMER_STATE]: CustomerReducer,
  [NOTIFICATION_STATE]: NotificaitonReducer,
});

export default rootReducer;
