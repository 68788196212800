import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx'
const useStyles = makeStyles((theme) => ({
  logo: {
    width: 40,
    height: 40,
    backgroundColor: '#fff',
    borderRadius: 50,
    '& img': {
      width: 28,
      transform: "translate(6px,3px)",
    },

    '&.large': {
      width: 100,
      height: 100,
      borderRadius: 100,
      boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
    },

    '&.large img': {
      width: 60,
      transform: "translate(20px,15px)",
    }
    
  }
}))
const Logo = (props) => {
  const styles = useStyles()
  return (
    <div className={clsx(styles.logo,props.size)}>
      <img
        alt="Logo"
        src="/static/vahalla.png"
        {...props}
      />
    </div>
  );
}

export default Logo;
