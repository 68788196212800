import React, { useState, useEffect, useCallback } from 'react';
import EventEmitter from 'eventemitter3';
import { Typography, fade, makeStyles, Box, Button } from '@material-ui/core';

let dialogEvent = new EventEmitter();

export const showAlert = (title, message, okText = 'OK') => {
  return new Promise((resolve, reject) => {
    const config = { title, message, okText , resolve, reject }
    dialogEvent.emit('show_dialog', config);
  });
};

export const showConfirm = (title, message, okText = 'OK', cancelText = 'Cancel') => {
  return new Promise((resolve, reject) => {
    const config = {  title, message, okText, cancelText, resolve, reject }
    dialogEvent.emit('show_dialog', config);
  });
};

const Dialog = ({ title, message, okText, cancelText, input, resolve, reject, uniq }) => {
  const classes = useStyles();
  return <div className={classes.overlayIcon} >
    <Typography color="secondary" variant="h3" >
      {title}
    </Typography>
    <Box width={400} style={{ padding: 20 }}>
      <Typography align='center' color="textPrimary" variant="body1">
        {message}
      </Typography>
    </Box>
    <Box style={{ display:"flex" , width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
    {/* <Box style={{ display:"flex" , width: '100%', flexDirection: 'row', justifyContent:'space-evenly' }}> */}
      <Button
        type={'submit'}
        title={okText}
        onClick={()=> {
          resolve()
          dialogEvent.emit('close_dialog', uniq);
        }}
      />
      {(!!cancelText) &&<Button
        type={'submit'}
        title={cancelText}
        onClick={()=> {
          reject('Dialog rejected')
          dialogEvent.emit('close_dialog', uniq);
        }}
      />}
    </Box>
  </div>
}

const DialogProvider = () => {
  const classes = useStyles();
  const [dialogs, setDialogs] = useState([])

  const getUnique = useCallback(() => {
    return btoa(Date.now().toString())
  })

  const showDialog = (config) => {
    if (config) {
      config.uniq = getUnique()
      setDialogs(list => [...list, config])
    }
  }

  // const onChange = (config, index) => {

  // }

  const onClose = ( uniq ) => {
    setDialogs(list => list.filter(item => item.uniq !== uniq))
  }

  useEffect(() => {
    dialogEvent.on('show_dialog', showDialog);
    dialogEvent.on('close_dialog', onClose);
    return () => {
      dialogEvent.off('show_dialog', showDialog);
      dialogEvent.off('close_dialog', onClose);
    }
  }, [])
  return (dialogs.length > 0) ? (
    <div className={classes.overlayStyle} >
      {<Dialog  {...dialogs.slice(-1).pop()} />}
    </div>
  ) : null
}
export default DialogProvider;

const useStyles = makeStyles((theme) => ({
  overlayStyle: {
    backgroundColor: fade(theme.palette.background.dark, 0.9),
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // pointerEvents: 'none',
    zIndex: 3001,
  },
  overlayIcon: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    aspectRatio: 1,
    padding: 20,
    borderRadius: 6,
    boxShadow: theme.shadows[10]
  },
}));
