import axios from 'src/utils/axios';
import { apiQuery } from './apiCall';

export const apiGetNotifications = () => {
  return axios.get('/api/notifications');
};

export const apiTest = () => {
  const gql = `query {
    GetPing {
      Message
    }
  }`
  return apiQuery(gql,null)
};