import { 
  CUSTOMER_UPDATE_GET_CUSTOMERS,
  CUSTOMER_UPDATE_GET_LIST_CUSTOMER,
  CUSTOMER_UPDATE_CUSTOMER_COUNT_TOTAL,
  CUSTOMER_UPDATE_GET_CUSTOMER_INFO,
  CUSTOMER_UPDATE_GET_LIST_USER_CERTIFICATE,
} from '../actions/CustomerAction';

export const CUSTOMER_STATE = 'customer';
        
const INITIAL_STATE = {
  customers: [],
  countTotal: 0,
  customerInfo: null,
  listUserCertificate: [],
};

export const CustomerReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CUSTOMER_UPDATE_GET_CUSTOMERS:
      return { ...state, customers: action.payload }
    case CUSTOMER_UPDATE_GET_LIST_CUSTOMER:
      return { ...state, customers: action.payload }
    case CUSTOMER_UPDATE_CUSTOMER_COUNT_TOTAL:
      return { ...state, countTotal: action.payload }
    case CUSTOMER_UPDATE_GET_CUSTOMER_INFO:
      return { ...state, customerInfo: action.payload }
    case CUSTOMER_UPDATE_GET_LIST_USER_CERTIFICATE:
      return { ...state, listUserCertificate: action.payload }
    default:
      return state
  }
}

export const CustomerSelector = (state) => state[CUSTOMER_STATE];
