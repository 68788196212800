import { put, call, take } from "redux-saga/effects";
import {
  CUSTOMER_GET_CUSTOMERS,
  updateGetCustomers,
  CUSTOMER_GET_LIST_CUSTOMER,
  updateGetListCustomer,
  updateCustomerCountTotal,
  CUSTOMER_GET_CUSTOMER_INFO,
  updateGetCustomerInfo,
  CUSTOMER_GET_LIST_USER_CERTIFICATE,
  updateGetListUserCertificate,
  CUSTOMER_SET_BLOCK_USER,
} from "src/actions/CustomerAction";
import {
  apiGetCustomers,
  apiGetListCustomer,
  apiGetCustomerInfo,
  apiGetListUserCertificate,
  apiSetBlockUser,
} from "src/api/CustomerApi";
import { handleStatusError } from ".";
import { hideSpinner, showAlert, showSpinner } from "src/components";

export function* workerGetCustomers() {
  try {
    yield call(showSpinner, "กำลังค้นหาข้อมูล");
    const response = yield call(apiGetCustomers);
    if (response.data) {
      yield put(updateGetCustomers(response.data?.customers));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}
export function* watcherGetCustomers() {
  while (true) {
    yield take(CUSTOMER_GET_CUSTOMERS);
    yield call(workerGetCustomers);
  }
}

export function* workerGetListCustomer(action) {
  try {
    yield call(showSpinner, "กำลังค้นหาข้อมูล");
    const reqData = {
      progressStatus: action?.payload?.progressStatus || "",
      searchType: action?.payload?.searchType || "",
      searchWord: action?.payload?.searchWord || "",
      sortBy: action?.payload?.sortBy || "",
      sortType: action?.payload?.sortType || "",
      limit: action?.payload?.limit || "10",
      page: action?.payload?.page || "1",
    };
    const response = yield call(apiGetListCustomer, reqData);
    if (response.data) {
      yield put(updateGetListCustomer(response.data?.ListUserInfo?.userInfos));
      yield put(updateCustomerCountTotal(response.data?.ListUserInfo?.countTotal));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetListCustomer() {
  while (true) {
    const action = yield take(CUSTOMER_GET_LIST_CUSTOMER);
    yield call(workerGetListCustomer, action);
  }
}

export function* workerGetCustomerInfo(action) {
  try {
    yield call(showSpinner, "กำลังค้นหาข้อมูล");
    const reqData = {
      userId: action?.payload?.userId || "",
    };
    const response = yield call(apiGetCustomerInfo, reqData);
    if (response.data) {
      if (!response.data?.GetUserInfo?.userId) {
        response.data.GetUserInfo.userId = action?.payload?.userId;
      }
      yield put(updateGetCustomerInfo(response.data?.GetUserInfo));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetCustomerInfo() {
  while (true) {
    const action = yield take(CUSTOMER_GET_CUSTOMER_INFO);
    yield call(workerGetCustomerInfo, action);
  }
}

export function* workerGetListUserCertificate(action) {
  try {
    yield call(showSpinner, "กำลังค้นหาข้อมูล");
    const response = yield call(apiGetListUserCertificate, action?.payload);
    if (response.data) {
      yield put(updateGetListUserCertificate(response.data?.ListUserCertificate?.listUserCertificate));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}
export function* watcherGetListUserCertificate() {
  while (true) {
    const action = yield take(CUSTOMER_GET_LIST_USER_CERTIFICATE);
    yield call(workerGetListUserCertificate, action);
  }
}

export function* workerSetBlockUser(action) {
  try {
    yield call(showSpinner, "กำลังค้นหาข้อมูล");
    const response = yield call(apiSetBlockUser, action?.payload?.data);
    if (response.data) {
      if (response.data?.SetBlockUser?.success) {
        const callbackFn = action?.payload?.callback || new Function;
        yield call(callbackFn);
      }
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherSetBlockUser() {
  while (true) {
    const action = yield take(CUSTOMER_SET_BLOCK_USER);
    yield call(workerSetBlockUser, action);
  }
}
