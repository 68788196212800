import React, { useState, useEffect, useRef } from 'react';
import _, { toUpper } from 'lodash';
import EventEmitter from 'eventemitter3';
import { Typography, fade, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

let dialogEvent = new EventEmitter();

const LOADING_MESSAGE = 'Loading...';

export const showSpinner = (message) => dialogEvent.emit('show_spinner', message);

export const hideSpinner = (close_all) => dialogEvent.emit('hide_spinner', close_all);

const ModalSpinner = () => {
  const classes = useStyles();
  const [messages, setMessages] = useState([])
  const eventCount = useRef(0)

  const setOverlay = (visible, option) => {
    if (visible) {
      setMessages(_.concat([option || LOADING_MESSAGE], messages))
    } else {
      setMessages(option ? [] : _.tail(messages))
    }
  }

  const showOverlay = (option) => {
    eventCount.current += 1
    setOverlay(true, option)
  }
  const hideOverlay = (option) => {
    if (option?.toUpperCase() === "Y") {
      while (eventCount.current > 0) {
        eventCount.current -= 1;
      }
    } else {
      eventCount.current -= 1;
    }

    if (eventCount.current <= 0) {
      setOverlay(false)
    }
  }

  useEffect(() => {
    dialogEvent.on('show_spinner', showOverlay);
    dialogEvent.on('hide_spinner', hideOverlay);
    return () => {
      dialogEvent.off('show_spinner', showOverlay);
      dialogEvent.off('hide_spinner', hideOverlay);
    }
  }, [])
  return (messages.length > 0) ? (
    <div className={classes.overlayStyle} >
      <div className={classes.overlayIcon} >
        <CircularProgress color="secondary" />
        <br />
        <Typography color="textPrimary" variant="body1" >
          {_.head(messages)}
        </Typography>
      </div>

    </div>
  ) : null
}
export default ModalSpinner;

const useStyles = makeStyles((theme) => ({
  overlayStyle: {
    backgroundColor: fade(theme.palette.background.dark, 0.9),
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3000,
  },
  overlayIcon: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    aspectRatio: 1,
    padding: 20,
    borderRadius: 6,
    boxShadow: theme.shadows[10]
  },
}));
