export const NOTIFICATION_GET_LIST = "NOTIFICATION_GET_LIST";
export const NOTIFICATION_UPDATE_LISTS = "NOTIFICATION_UPDATE_LISTS";

export const getNotificationList = (data) => ({
    type: NOTIFICATION_GET_LIST,
    payload: data
})

export const updateNotificationList = (data) => ({
    type: NOTIFICATION_UPDATE_LISTS,
    payload: data
})