export const CUSTOMER_GET_CUSTOMERS = "CUSTOMER_GET_CUSTOMERS";
export const CUSTOMER_UPDATE_GET_CUSTOMERS = "CUSTOMER_UPDATE_GET_CUSTOMERS";
export const CUSTOMER_GET_LIST_CUSTOMER = "CUSTOMER_GET_LIST_CUSTOMER";
export const CUSTOMER_UPDATE_GET_LIST_CUSTOMER = "CUSTOMER_UPDATE_GET_LIST_CUSTOMER";
export const CUSTOMER_UPDATE_CUSTOMER_COUNT_TOTAL = "CUSTOMER_UPDATE_CUSTOMER_COUNT_TOTAL";
export const CUSTOMER_GET_CUSTOMER_INFO = "CUSTOMER_GET_CUSTOMER_INFO";
export const CUSTOMER_UPDATE_GET_CUSTOMER_INFO = "CUSTOMER_UPDATE_GET_CUSTOMER_INFO";
export const CUSTOMER_GET_LIST_USER_CERTIFICATE = "CUSTOMER_GET_LIST_USER_CERTIFICATE";
export const CUSTOMER_UPDATE_GET_LIST_USER_CERTIFICATE = "CUSTOMER_UPDATE_GET_LIST_USER_CERTIFICATE";
export const CUSTOMER_SET_BLOCK_USER = "CUSTOMER_SET_BLOCK_USER";

export const getCustomers = (data) => ({
  type: CUSTOMER_GET_CUSTOMERS,
  payload: data,
});

export const updateGetCustomers = (data) => ({
    type: CUSTOMER_UPDATE_GET_CUSTOMERS,
    payload: data,
});

export const getListCustomer = (data) => ({
  type: CUSTOMER_GET_LIST_CUSTOMER,
  payload: data,
});

export const updateGetListCustomer = (data) => ({
    type: CUSTOMER_UPDATE_GET_LIST_CUSTOMER,
    payload: data,
});

export const updateCustomerCountTotal = (data) => ({
  type: CUSTOMER_UPDATE_CUSTOMER_COUNT_TOTAL,
  payload: data,
});

export const getCustomerInfo = (data) => ({
  type: CUSTOMER_GET_CUSTOMER_INFO,
  payload: data,
});

export const updateGetCustomerInfo = (data) => ({
  type: CUSTOMER_UPDATE_GET_CUSTOMER_INFO,
  payload: data,
});

export const getListUserCertificate = (data) => ({
  type: CUSTOMER_GET_LIST_USER_CERTIFICATE,
  payload: data,
});

export const updateGetListUserCertificate = (data) => ({
  type: CUSTOMER_UPDATE_GET_LIST_USER_CERTIFICATE,
  payload: data,
});

export const setBlockUser = (data) => ({
  type: CUSTOMER_SET_BLOCK_USER,
  payload: data,
});