import React, {
  createContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import {url} from '../config'
import history from '../history'
import useAuth from '../hooks/useAuth';

const httpLink = new HttpLink({ uri: url().API_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
  let accessToken = localStorage.getItem('accessToken')
  if(accessToken) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: 'bearer '+ accessToken || "",
      }
    }));
  }
  return forward(operation);
})

const onErrorLink = (logout) => onError(({ networkError }) => {
  if (networkError?.statusCode === 401) {
    logout()
  }
})

export const ApolloConnect = ({ children }) => {
  const { logout } = useAuth()
  const [client, setClient] = useState()
  useEffect(() => {
    const client = new ApolloClient({
      link: from([
        onErrorLink(logout),
        authMiddleware,
        httpLink
      ]),
      cache: new InMemoryCache()
    });
    setClient(client)
  },[])

  if(!client) {
    return <>
      { children }
    </>
  }
  return <ApolloProvider client={client}>
    { children }
  </ApolloProvider>
}