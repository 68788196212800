// import {  } from '../config';
import _ from 'lodash';
// import { getAccessToken } from 'src/utils/session';
import axios from "src/utils/axios";
const config = () => {}
const AuthorizeApi = ""
export const ERROR_SESSION_LOST = 'error_session_lost';
export const ERROR_CONNECTION_TIMEOUT = 'error_connection_timeout';

const getAccessToken = () => {
    return localStorage.getItem('accessToken');
}

const handleStatus = (json) => {
    // switch (json.status) {
    //     case 99:
    //         throw new Error(ERROR_SESSION_LOST);
    //     case 504:
    //         throw new Error(ERROR_CONNECTION_TIMEOUT);
    //     default:
    //         throw new Error(json.message || 'GraphQL Error');
    // }
    if (json.errors) {
        throw new Error(json.errors[0]?.message || 'GraphQL Error');
    }
    if (json.status != 200 && json?.message) {
        throw new Error(json.message);
    }
    if (json.success || json.data) {
        return json
    }
    throw new Error('response.data not found');
}

export const apiCall = (type, uri, req, body) => {
    if (body) {
        body = JSON.stringify(body)
    }
    let params = [];
    _.forEach(req || {}, (val, key) => (
        params.push(`${key}=${encodeURIComponent(val)}`)
    ))
    let url = `${config().API_ENDPOINT}${uri}`
    if (params.length > 0) {
        url = `${url}?${params.join('&')}`
    }

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
    let token = getAccessToken()
    if(token) {
        headers['Authorization'] = 'Bearer ' + token
    }
    // console.log('####### Header #####',headers)
    return fetch(url, {
        // credentials: 'include',
        method: type,
        headers: headers,
        body: body,
    }).then(data => {
        if (data.status == 200) {
            return data.json()
        }
        else if (data.status == 401) {
            window.location = window.location.origin + "/login";
            throw new Error("Invalid Token");
        }
        console.log( '####### >',url, data)
        console.log('body >>> ', body)
        
        throw new Error('Please contact administrator (status ' + data.status + ')')

    }).then((json) => {
        console.log('||||||| API ', url, '\n', json)
        return handleStatus(json);
    })
}

export const apiQuery = (query, variables) => {
    let body = {
        query: query,
        variables: variables || {},
    }
    let url = `query`
    return apiCall('POST', AuthorizeApi + url, null, body)
}

export const apiUploadMultipleFiles = (uri, files, body) => {
    let params = [];
    _.forEach({}, (val, key) => (
        params.push(`${key}=${encodeURIComponent(val)}`)
    ));

    let url = `${config().API_ENDPOINT}${uri}`;
    if (params.length > 0) {
        url = `${url}?${params.join('&')}`;
    }
   
    let headers = {
        // 'Authorization': getAccessToken(),
    };

    let formData = new FormData();
    if (files) {
        files.forEach((val) => {
            let name = val.name?.split('.')[0] || "";
            formData.append(name?.toLowerCase(), val);
        });
    }

    return fetch(url, {
        method: "POST",
        headers: headers,
        body: formData,
    })
    .then(data => {
        if (data.status == 200) {
            return data.json();
        }
        else if (data.status == 401) {
            window.location = window.location.origin + "/login";
            throw new Error("Invalid Token");
        }
        console.log( '####### >',url, data);
        console.log('body >>> ', body);
        
        throw new Error('Please contact administrator (status ' + data.status + ')');

    }).then((json) => {
        console.log('||||||| API ', url, '\n', json);
        return handleStatus(json);
    });
}

export const apiSubscription = (query, variables, ws) => {
    let body = {
        query: query,
        variables: variables || {},
    }
    let url = `query`;
    apiSubscriptionCall(AuthorizeApi + url, body, ws);
}

export const apiSubscriptionCall = (uri, body, wsObj = new Function) => {
    let url = `${config().API_ENDPOINT}${uri}`;
    if (url?.search("https") > -1) {
        url = url.replaceAll("https", "wss");
    } else {
        url = url.replaceAll("http", "ws");
    }

    // WebSocket status: CONNECTING, OPEN, CLOSING or CLOSED
    var ws = new WebSocket(url, "graphql-ws");

    ws.OnCloseTrigger = () => {
        ws.send(JSON.stringify({ payload: {}, type: "stop" }));
        ws.close("1000", "Close Trigger");
    };

    ws.onopen = () => {
        // send message to init connection
        ws.send(JSON.stringify({ payload: {}, type: "connection_init" })); // send a first message for open connection
        
        // after connection opened
        let reqData = {
            id: "1",
            payload: body,
            type: "start",
        };
        ws.send(JSON.stringify(reqData)); // send a message
        
        wsObj(ws); // set WebSocket to useState
    };
    
    // ws.onmessage = (e) => {
    //     // connection closed
    //     console.log(`||| old ws.onmessage => ${e.data}`);
    // };

    // ws.onerror = (e) => {
    //     // an error occurred
    //     console.log("||| ws.onerror => ", e.message);
    // };
    
    // ws.onclose = (e) => {
    //     // connection closed
    //     console.log(`||| ws.onclose => code: ${e.code}, reason: ${e.reason}`);
    // };
};

export const apiCall2 = (type, uri, req, body) => {
    if (body) {
        body = JSON.stringify(body)
    }
    let params = [];
    _.forEach(req || {}, (val, key) => (
        params.push(`${key}=${encodeURIComponent(val)}`)
    ))
    let url = `http://localhost:8080/robo/${uri}`
    if (params.length > 0) {
        url = `${url}?${params.join('&')}`
    }

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Authorization': getAccessToken() //? 'Bearer ' + getAccessToken() : undefined ,
    }
    // console.log('####### Header #####',headers)
    return fetch(url, {
        // credentials: 'include',
        method: type,
        headers: headers,
        body: body,
    }).then(data => {
        if (data.status == 200) {
            return data.json()
        }
        else if (data.status == 401) {
            window.location = window.location.origin + "/login";
            throw new Error("Invalid Token");
        }
        console.log( '####### >',url, data)
        console.log('body >>> ', body)
        
        throw new Error('Please contact administrator (status ' + data.status + ')')

    }).then((json) => {
        console.log('||||||| API ', url, '\n', json)
        return handleStatus(json);
    })
}

export const apiQueryLocal = (query, variables) => {
    let body = {
        query: query,
        variables: variables || {},
    }
    let url = `query`
    return apiCall2('POST', AuthorizeApi + url, null, body)
}
