import { apiQuery } from "./apiCall";
// import client from '../client'
import axios from "src/utils/axios";

export const apiGetCustomers = () => {
    return axios.get('/api/customers');
};

export const apiGetListCustomer = (variables) => {
    const gql = `
        query ListUserInfoInput(
            $progressStatus: String!
            $searchType: String!
            $searchWord: String!
            $sortBy: String!
            $sortType: String!
            $limit: String!
            $page: String!
        ) {
            ListUserInfo(input: {
                progressStatus: $progressStatus
                searchType: $searchType
                searchWord: $searchWord
                sortBy: $sortBy
                sortType: $sortType
                limit: $limit
                page: $page
            }) {
                userInfos {
                    userId
                    citizenIdCardNumber
                    firstNameTh
                    firstNameEn
                    lastNameTh
                    lastNameEn
                    mobileNumber
                    mobileVerifiedDate
                    email
                    emailVerifiedDate
                    registeredBy
                    verifiedBy
                    isBlocked
                    oaStatus
                    createdAt
                    updatedAt
                }
                countTotal
            }
        }
    `;
    
    return apiQuery(gql, variables);
};

export const apiGetCustomerInfo = (variables) => {
    const gql = `
        query ListUserInfoInput(
            $userId: String!
        ) {
            GetUserInfo (input: {
                userId: $userId
            }) {
                citizenCardNumber
                username
                mobileNumber
                mobileVerifiedDate
                email
                emailVerifiedDate
                registeredBy
                verifiedBy
                deviceId
                deviceModel
                deviceOs
                fcmToken
                isBlocked
                failedAttempt
                isMobileDeprecated
                isEmailDeprecated
                bankCustomerId
                isAcceptedConditionSaving
                isAcceptedConditionFcn
                groupOpenAccountStep {
                    formData {
                        code
                        isCompleted
                        titleEn
                        titleTh
                        isShow
                        detail {
                            code
                            isCompleted
                            titileEn
                            titleTh
                            isShow
                        }
                    }
                    openAccount {
                        code
                        isCompleted
                        titleEn
                        titleTh
                        isShow
                        detail {
                            code
                            isCompleted
                            titileEn
                            titleTh
                            isShow
                        }
                    }
                }
                oaStatus
                openAccountFlow
                firstNameTh
                firstNameEn
                lastNameTh
                lastNameEn
                birthDate
                createdDate
                updatedDate
            }
        }
    `;
    return apiQuery(gql, variables);
};

export const apiGetListUserCertificate = (userId) => {
    const gql = `
        query {
            ListUserCertificate(input: {
                userId: "${userId}"
            }) {
                listUserCertificate {
                    type
                    status
                    createdAt
                }
            }
        }
    `;
    return apiQuery(gql, null);
};

export const apiSetBlockUser = (variables) => {
    const gql = `
        mutation SetBlockUserInput (
            $userId: String!
            $isBlocked: Boolean!
        ) {
            SetBlockUser(input: {
                userId: $userId
                isBlocked: $isBlocked
            }) {
                success
            }
        }
    `;
    return apiQuery(gql, variables);
};

