export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

const configs = {
	local: {
  //  API_ENDPOINT: 'http://localhost:8080/api/operation/v1/query',
  //  AUTH: 'http://localhost:8080' 
    API_ENDPOINT: 'https://rhb-backoffice-sit.codefin.dev/api/operation/v1/query',
    AUTH: 'https://rhb-backoffice-sit.codefin.dev'
	},
  dev: {
    API_ENDPOINT: 'https://rhb-backoffice-dev.codefin.dev/api/operation/v1/query',
    AUTH: 'https://rhb-backoffice-dev.codefin.dev'
  },
  sit: {
		API_ENDPOINT: 'https://rhb-backoffice-sit.codefin.dev/api/operation/v1/query',
    AUTH: 'https://rhb-backoffice-sit.codefin.dev'
  },
  uat: {
    API_ENDPOINT: 'https://rhb-backoffice-uat.codefin.dev/api/operation/v1/query',
    AUTH: 'https://rhb-backoffice-uat.codefin.dev'
  },
  prod: {
    API_ENDPOINT: 'https://backoffice.th.rhbtradesmart.com/api/operation/v1/query',
    AUTH: 'https://backoffice.th.rhbtradesmart.com'
	}, 
}

const hosts = {
  'localhost': 'local',
	'rhb-backoffice-dev.codefin.dev': 'dev',
  'rhb-backoffice-sit.codefin.dev': 'sit',
  'rhb-backoffice-uat.codefin.dev': 'uat',
  'backoffice.th.rhbtradesmart.com': 'prod',
}

export const url = () => {
  const appEnv = process.env.REACT_APP_ENV;
  if (configs[appEnv]) {
    return configs[appEnv]
  }

  let host = hosts[window.location.host] || 'local'

  return configs[host]
}
