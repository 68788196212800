import { NOTIFICATION_UPDATE_LISTS } from "../actions/NotificationAction";

export const NOTIFICATION_STATE = 'notifications';
const INITIAL_STATE = {
  notifications: [],
};

export const NotificaitonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_UPDATE_LISTS:
      return { ...state ,notifications : action.payload };
    default:
      return state;
  }
}

export const NotificationSelector = (state) => {
  return state[NOTIFICATION_STATE];
}